import { Tab, Row, Container, Col, Nav, Carousel, CarouselItem} from 'react-bootstrap';
import { ProjectCard } from './ProjectCard';
import colorSharp2 from "../assets/img/color-sharp-2.png";
import TrackVisibility from 'react-on-screen';
import wearables from '../assets/img/wearables.png'
import wearables2 from '../assets/img/wearables2.png';
import wearables3 from '../assets/img/wearables3.png';
import wetrade1 from '../assets/img/wetrade1.png';
import wetrade2 from '../assets/img/wetrade2.png';
import wetrade4 from '../assets/img/wetrade4.png';
import ethers1 from '../assets/img/ethers1.png';
import ethers from '../assets/img/ethers.png';
import ethers2 from '../assets/img/ethers2.png'
import cb1 from '../assets/img/cb1.png'
import cb2 from '../assets/img/cb2.png'
import cb3 from '../assets/img/cb3.png'


export const Projects = () => {
  const comingSoon = [
    {
      title: "Coming Soon!",
      description: "",
      imgUrl: ""
    }
  ];

  const ClonebookProject = [
    {
      title: "React and Redux" ,
      description: "Implemented data stores with Redux to centralize state logic on React, for features including dynamic form validation and dark mode",
      imgUrl: cb3
    },
    {
      title: "Node.js, OAuth2.0, and JWT" ,
      description: "Implemented Node.js backend mapping objects onto MongoDB database with Mongoose.js; Managed secrets with JWTs by encrypting bearer tokens to verify OAuth credentials.",
      imgUrl: cb2,
    },
    {
      title: "Docker and AWS" ,
      description: "Used docker-compose to define and build multi-container application and deployed on Amazon ECS. Also learned how to implement nginx load balancer and reverse proxies.",
      imgUrl: cb1,
    }
  ];

  const wearablesProject = [
    {
      title: "OOP and ORMs" ,
      description: "Integrated PostreSQL into object model using ActiveRecord. Also my first full-stack project. Gained practical experience on the MVC pattern.",
      imgUrl: wearables
    },
    {
      title: "Authorization and Authentication" ,
      description: "Used Devise and Pundit to set user policies and manage access. This was very helpful for my second project as the database schema was more advanced.",
      imgUrl: wearables2,
    },
    {
      title: "HTTPS and AJAX" ,
      description: "Gained practical experience on using services by making API calls. Examples include geolocation/spatial mapping and enabling payments through Stripe.",
      imgUrl: wearables3,
    }
  ];
  const wetradeProject = [
    {
      title: "Background Jobs and Batch Processing" ,
      description: "Used Sidekiq to run batch jobs to maintain validity of database.",
      imgUrl: wetrade1,
    },
    {
      title: "WebSockets" ,
      description: "Enabled WebSockets through ActionCable to implement live-chat feature for groups.",
      imgUrl: wetrade4
    },
    {
      title: "Advanced Schema Design" ,
      description: "Furthered my database skills by designing schemas for databases with much more interrelated tables.",
      imgUrl: wetrade2
    }
  ];
  const etherlotteryProject = [
    {
      title: "Smart Contract Testing and Deployment" ,
      description: "Used Remix initially and then Truffle to deploy and test my smart contract. Took reentrancy attacks into consideration while writing the contract.",
      imgUrl: ethers2,
    },
    {
      title: "Oracles" ,
      description: "Used Chainlink's VRFConsumerBase randomness generator as an oracle service to make use of randomness during lottery winner selection.",
      imgUrl: ethers1
    },
    {
      title: "Javascript and Web3" ,
      description: "Used web3.js to communicate with and call smart contract functions from app.",
      imgUrl: ethers
    }
  ]

  const projectUrls = [
    {clonebook: ""},
    {wearables: ""},
    {wetrade: ""},
    {etherlottery: ""}
  ]


  return(

    <section className='project' id='project'>
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({isVisible}) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>Projects</h2>
                  <p className='mb-0'>Projects that taught me different things along the way!</p>
              </div>}
            </TrackVisibility>
            <Tab.Container id="projects-tabs" defaultActiveKey="first">

              <Nav variant="pills" className="nav-pills mb-5 mt-5 justify-content-center align-items center" id="pills-tab">
                <Nav.Item >
                  <Nav.Link eventKey="first">Clonebook</Nav.Link>
                </Nav.Item>
                <Nav.Item >
                  <Nav.Link eventKey="second">Wearables</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='third'>WeTrade</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='fourth'>ETH Lottery</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Row>
                    {
                      ClonebookProject.map((project,index) => {
                        return (
                          <ProjectCard key={index}
                          {...project}
                          />
                        )
                      })
                    }
                  </Row>
                  <Row>
                    <a className="project-link" href="http://18.189.255.61:3001/" target="_blank" rel="noreferrer"> Check it out!</a>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <Row>
                    {
                      wetradeProject.map((project,index) => {
                        return (
                          <ProjectCard key={index}
                          {...project}
                          />
                        )
                      })
                    }
                  </Row>
                  <Row>
                    <a className="project-link" href="https://we-trade-project.herokuapp.com/" target="_blank" rel="noreferrer">Check it out!</a>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Row>
                    {
                      wearablesProject.map((project,index) => {
                        return (
                          <ProjectCard key={index}
                          {...project}
                          />
                        )
                      })
                    }
                  </Row>
                  <Row>
                    <a className="project-link" href="http://18.189.255.61:3000/" target="_blank" rel="noreferrer">Check it out!</a>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="fourth">
                  <Row>
                    {
                      etherlotteryProject.map((project,index) => {
                        return (
                          <ProjectCard key={index}
                          {...project}
                          />
                        )
                      })
                    }
                  </Row>
                  <Row>
                    <a className="project-link" href="https://eth-lottery-deployment-gxnytbroh-tanakpek.vercel.app/" target="_blank" rel="noreferrer">Check it out!</a>
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" alt="decoration" src={colorSharp2}/>
    </section>
  )
}
