import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import {useState, useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
//import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/icons8-github.svg";
import navIcon2 from "../assets/img/icons8-instagram.svg";
import navIcon3 from "../assets/img/icons8-linkedin.svg";

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if(window.scrollY > 50){
        setScrolled(true);
      }
      else{
        setScrolled(false)
      }
    }
    window.addEventListener("scroll", onScroll);
    return () => {window.removeEventListener("scroll", onScroll)}
  },[])

  const onUpdateActiveLink = (value) => { setActiveLink(value); }

  return (
  <Navbar expand="lg" className={scrolled ? "scrolled" : ""}>
    <Container>
      <Navbar.Brand href="#home"></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav">
        <span className="navbar-toggler-icon"></span>
      </Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link href="#home" className={activeLink === 'home' ? "active navbar-link" : "navbar-link"} onClick={()=> onUpdateActiveLink('home')}>Home</Nav.Link>
          <Nav.Link href="#skills" className={activeLink === 'skills' ? "active navbar-link" : "navbar-link"} onClick={()=> onUpdateActiveLink('skills')}>Skills</Nav.Link>
          <Nav.Link href="#project" className={activeLink === 'projects' ? "active navbar-link" : "navbar-link"} onClick={()=> onUpdateActiveLink('projects')}>Projects</Nav.Link>
        </Nav>
        <span className="navbar-text">
          <div className="social-icon">
            <a href="https://github.com/Tanakpek" target="_blank"><img src={navIcon1} alt=""/></a>
            <a href="https://www.instagram.com/tanakpek1/?hl=en"  target="_blank"><img src={navIcon2} alt=""/></a>
            <a href="https://www.linkedin.com/in/tan-akpek-4a19b61a4/?originalSubdomain=ca" target="_blank"><img src={navIcon3} alt=""/></a>
          </div>

        </span>
      </Navbar.Collapse>
    </Container>
  </Navbar>
  );
}
