import {useState, useEffect} from "react";
import {Container, Row, Col, Nav} from 'react-bootstrap';
import {ArrowRightCircle} from "react-bootstrap-icons"
import 'animate.css';
import TrackVisibility from "react-on-screen";
import { isVisible } from "@testing-library/user-event/dist/utils";
import video from '../assets/vid/abstract-gradient-background-loop-2022-08-04-09-00-51-utc.mp4'


export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(Math.random() * 250);
  const period = 2000;
  const toRotate = ["Full-Stack Developer", "Software Engineer", "Web Developer"];

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta)
    return () => {clearInterval(ticker)};
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);
    console.log(updatedText)
    setText(updatedText);
    if (isDeleting){
      setDelta(prevDelta => prevDelta /1.8 + 10)
    }
    if(!isDeleting && updatedText === fullText){
      setIsDeleting(true);
      setDelta(period);
    }
    else if(isDeleting && updatedText === ''){
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setDelta(200);
    }
  }
  return (
    <section className="banner" id="home">
      <video id="banner_video" autoplay="" muted loop playsInline>
        <source src={video} type="video/mp4"/>
     </video>

      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
            {({isVisible}) =>
              <div className= "animate__animated animate__fadeIn">
                <span className="tagline">Welcome to my Portfolio</span>
                <h1>{`Hi. I'm Tan, `}<span className="wrap">{text}</span></h1>
                <p>I'm a exceedingly curious, passionate developer ready to take on any challenge!</p>
                <Nav.Link href="#connect">Let's Connect  <ArrowRightCircle size={25} /> </Nav.Link>
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
