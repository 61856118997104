import Carousel from 'react-multi-carousel';
import {Container, Row, Col} from 'react-bootstrap';
import 'react-multi-carousel/lib/styles.css';
import meter1 from '../assets/img/meter1.svg';
import meter3 from '../assets/img/meter3.svg';
import meter4 from '../assets/img/meter4.svg';
import colorSharp from '../assets/img/color-sharp.png';
export const Skills = () => {

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <section className="skill" id="skills">
      <Container>
        <Row>
          <Col>
            <div className="skill-bx">
              <h2>
                Skills
              </h2>
              <p>In my opinion, no amount of learning goes to waste</p>
              <Carousel responsive={responsive} infinite={true} className="skill-slider">
                <div className="item">
                  <img src={meter1} alt="skill image."/>
                  <h5>Web Development - MERN and Rails </h5>
                </div>
                <div className="item">
                  <img src={meter3} alt="skill image."/>
                  <h5>SQL</h5>
                </div>
                <div className="item">
                  <img src={meter1} alt="skill image."/>
                  <h5>Google Cloud Platform</h5>
                </div>
                <div className="item">
                  <img src={meter3} alt="skill image."/>
                  <h5>Amazon Web Services</h5>
                </div>
                <div className="item">
                  <img src={meter3} alt="skill image."/>
                  <h5>Python</h5>
                </div>
                <div className="item">
                  <img src={meter4} alt="skill image."/>
                  <h5>Solidity</h5>
                </div>
              </Carousel>
              <img className="background-image-left" src={colorSharp}/>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )

}
