import {useState} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import contactImg from '../assets/img/contact-img.svg';
import emailjs from '@emailjs/browser';
import React, { useRef } from 'react';

export const Contact = () => {

  const form = useRef();



  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Send');
  const [status, setStatus] = useState({});

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_PUBLIC_KEY')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };


  const onFormUpdate = (category, value) => {
    setFormDetails({...formDetails, [category]: value})
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText("Sending...");
    emailjs.sendForm("service_ogm0bgm","template_jlkrjgo", form.current, 'jpy9gZ3ShZtd5CoUO').then((result) => {
        setStatus({success:true, message:"Message sent succesfully."});
        setButtonText('Sent');
      }, (error) => {
        setStatus({success:false, message:"Something went wrong. Please try agian later."})
        setButtonText('Send');
      });
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <img src={contactImg}/>
          </Col>
          <Col md={6}>
            <h2>Get In Touch</h2>
              <form ref={form} onSubmit={handleSubmit}>
                <Row>
                  <Col sm={6} className="px-1 contact_field">
                    <input name="user_firstname" type="text" value={formDetails.firstName} placeholder="First Name" onChange={(e) => { onFormUpdate( 'firstName', e.target.value ) }}/>
                  </Col>
                  <Col sm={6} className="px-1 contact_field">
                    <input name="user_lastname" type="text" value={formDetails.lastName} placeholder="Last Name" onChange={(e) => { onFormUpdate( 'lastName', e.target.value ) }}/>
                  </Col>

                  <Col sm={6} className="px-1 contact_field">
                    <input name="email" type="email" value={formDetails.email} placeholder="email" onChange={(e) => { onFormUpdate( 'email', e.target.value ) }}/>
                  </Col>
                  <Col sm={6} className="px-1 contact_field">
                    <input name="tel" type="tel" value={formDetails.phone} placeholder="Phone No." onChange={(e) => { onFormUpdate( 'phone', e.target.value ) }}/>
                  </Col>


                  <Col sm={12} className="px-1 contact_field">
                    <textarea name="message" row="6" value={formDetails.message} placeholder="message" onChange={(e) => { onFormUpdate( 'message', e.target.value ) }}/>
                    <button type="submit"><span>{buttonText}</span></button>
                  </Col>

                  {
                    status.message &&
                    <Col>
                      <p className={status.success === false ?  "danger" : "success"}>{status.message}</p>

                    </Col>
                  }
                </Row>
              </form>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
